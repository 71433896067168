<script setup>
import { onMounted, ref, computed } from 'vue';

const props = defineProps({
    modelValue: String,
    class: String
});

defineEmits(['update:modelValue']);

const input = ref(null);

const inputClasses = computed(() => {
  const defaultClasses = "border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm"

  if ( ! props ) {
    return defaultClasses;
  }
  return [defaultClasses, props.class]
})

onMounted(() => {
    if (input.value.hasAttribute('autofocus')) {
        input.value.focus();
    }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
    <input
        v-bind="$attrs"
        ref="input"
        :class="inputClasses"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
    >
</template>
